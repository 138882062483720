<template>
    <div>
        <div v-if="id==1">
            <h1 class="title">Choose Interview Date</h1>
            
            <table class="table is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th width="50">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>15/2/2023</td>
                        <td>3:00pm</td>
                        <td>
                            <button class="button is-success is-small">Choose</button>
                        </td>
                    </tr>
                    <tr>
                        <td>15/2/2023</td>
                        <td>4:00pm</td>
                        <td>
                            <button class="button is-success is-small">Choose</button>
                        </td>
                    </tr>
                    <tr>
                        <td>15/2/2023</td>
                        <td>4:30pm</td>
                        <td>
                            <button class="button is-success is-small">Choose</button>
                        </td>
                    </tr>
                    <tr>
                        <td>16/2/2023</td>
                        <td>9:00am</td>
                        <td>
                            <button class="button is-success is-small">Choose</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="id==2">
            <h1 class="title">Application Pending</h1>
            
            <div class="notification is-warning content" >
                   
                   <p>Your application to Balmoral Healthcare is currently being reviewed by our team.</p>
                   <p>We will respond by email once this review has been completed.</p>
                   </div>
        </div>

        <div v-if="id==3">
            <h1 class="title">Further Information Required</h1>
            
            <form >
                      <div class="field">
                          <label class="label">Proof of Address</label>
                          <div class="control">
                              <input class="input" type="file">
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Two Proofs of ID</label>
                          <div class="control">
                              <input class="input" type="file"  >
                          </div>
                          <div class="control">
                              <input class="input" type="file"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">National Insurance Number</label>
                          <div class="control">
                              <input class="input" type="text"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Access NI Number</label>
                          <div class="control">
                              <input class="input" type="text"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Share Code (if required)</label>
                          <div class="control">
                              <input class="input" type="text" value="GHT563RT" >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Proof of Union</label>
                          <div class="control">
                              <input class="input" type="file"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">References</label>
                          <div class="control">
                              We require you to provide 2 references. Please send the following link to your references and ask them to submit the form as quickly as possible.
                              <a href="https://hw4applications.fed202.com/reference/1" target="_blank">https://hw4applications.fed202.com/reference/1</a>
                          </div>
                      </div>

  
                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" >Submit Details</button>
                          </div>
                      </div>
                  </form>
        </div>
    </div>
</template>

<script>
    
    export default {
      name: 'AddOrganisation',
        mixins: [],
        data: function () {
            return {
                   id : this.$route.params.id
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
              
            });
          
        },
        methods: {
            
           
        }
    }
    </script>